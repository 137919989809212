<template>
  <div class="legals">
    <b-container class="legals-content text-center">
      <h2>Aviso de Privacidad</h2>

      <b-row class="d-flex justify-content-center">
        <b-col md="12" class="information mt-5" style="text-align: justify">
          <p>
            BeeTelco S.A. de C.V., en adelante “Bee Telecom”, con domicilio en
            Calle 10 Norte 603 Oficina privada 4A SRecta a Cholula No. 308, San Andrés 
            Cholula, C.P. 72810, será responsable del tratamiento de sus datos personales, en
            cumplimiento a la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares y su Reglamento, así como en estricto
            apego a los principios rectores de la materia como lo son: licitud,
            consentimiento, información, calidad, finalidad, lealtad,
            proporcionalidad y responsabilidad; por lo que, Bee Telecom aplica
            las medidas de seguridad físicas, administrativas y tecnológicas
            pertinentes durante todo el tratamiento y ciclo de vida de sus datos
            personales, en los términos siguientes del presente aviso de
            privacidad:
          </p>

          <br />

          <p>
            <b>Primero.-</b>

            Bee Telecom, con el fin de brindarle servicios de calidad, procesar
            sus pagos, generar y enviar sus facturas, brindarle información y
            asesoría, darle seguimiento a quejas y/o sugerencias, así como,
            envío de publicidad, ofertas comerciales, nuevos servicios, y
            elaboración de estadísticas, solicita los siguientes datos
            personales:
          </p>

          <br />

          <ul style="list-style: none">
            <li>
              <small>
                Nombre completo del titular y/o razón social y/o denominación
                social;
              </small>
            </li>

            <li>
              <small>
                Copia simple del comprobante de domicilio (lugar donde se
                realizará la prestación de servicios), así como referencias de
                localización para la correcta ubicación de su domicilio y poder
                estar en aptitud de informar de la cobertura de nuestros
                servicios y en su caso la instalación de los equipos;
              </small>
            </li>

            <li>
              <small>Teléfono móvil;</small>
            </li>

            <li>
              <small>Teléfono de casa y/o teléfono de oficina; </small>
            </li>

            <li>
              <small>Correo electrónico;</small>
            </li>

            <li>
              <small>Copia simple de su identificación oficial;</small>
            </li>

            <li>
              <small>
                Copia simple del Acta Constitutiva y en su caso, de las Actas
                Extraordinarias y/o Poder del Representante Legal;
              </small>
            </li>

            <li>
              <small>Constancia de Situación Fiscal (R.F.C.); y</small>
            </li>

            <li>
              <small>
                Nombre del titular de la tarjeta de crédito o débito que se
                presente, número de la misma, y nombre de la institución
                bancaria.
              </small>
            </li>
          </ul>

          <p>
            <b>Segundo.-</b>

            Bee Telecom, realizará la transferencia de sus datos personales en
            caso de incumplimiento de pago, en ese entendido, Bee Telecom podrá
            transferir sus datos personales a Buró de Crédito, de conformidad
            con lo establecido en la fracción VIII del artículo 37 de la Ley
            Federal de Protección de Datos Personales en Posesión de los
            Particulares, incluyendo aquellas transferencias que, por Ley,
            mandamiento de autoridad competente o que por motivo del contrato de
            prestación de servicios resulten indispensables para la correcta
            ejecución del mismo.
          </p>

          <br />

          <p>
            Asimismo, Bee Telecom podrá transferir sus datos personales a
            terceras personas para fines estadísticos, sin embargo, usted podrá
            manifestar expresamente su oposición de conformidad con el punto
            tercero del presente aviso de privacidad.
          </p>

          <br />

          <p>
            <b>Tercero.-</b>

            Usted podrá ejercitar sus derechos ARCO (Acceso, Rectificación,
            Cancelación y Oposición), solicitando el acceso a sus datos
            personales y/o rectificando sus datos personales cuando estos sean
            inexactos o incompletos, limitando u oponiéndose al tratamiento de
            sus datos personales, y/o cancelar el uso y/o revocar su
            consentimiento, de manera personal, directamente en nuestras
            oficinas ubicadas en Calle 10 Norte 603 Oficina privada 4A Recta a Cholula No. 308,
             San Andrés Cholula, C.P. 72810, o a través del correo electrónico
            derechosarco@beetelecom.mx.
          </p>

          <br />

          <p>
            Asimismo, para el ejercicio de sus derechos ARCO, deberá elaborar
            una solicitud que será entregada de forma personal o en su caso
            enviada al correo electrónico señalado en el párrafo anterior, con
            los siguientes requisitos:
          </p>

          <br />

          <ul style="list-style: none">
            <li>
              <small>
                Nombre completo del titular y/ Razón Social y/o Denominación
                Social y en su caso nombre del Representante Legal;
              </small>
            </li>

            <li>
              <small>Copia simple de su identificación oficial;</small>
            </li>

            <li>
              <small>Correo electrónico; y</small>
            </li>

            <li>
              <small>
                Descripción breve de lo que solicita, ya sea el acceso a sus
                datos personales y/o la rectificación de sus datos personales
                cuando estos sean inexactos o incompletos, y/o la limitación u
                oposición al tratamiento de sus datos personales, y/o la
                cancelación del uso de los mismos, y/o la revocación de su
                consentimiento.
              </small>
            </li>
          </ul>

          <br />

          <p>
            Bee Telecom deberá acusar de recibida la solicitud, posteriormente,
            dentro de los siete días hábiles siguientes, por lo que Bee Telecom
            emitirá respuesta a la solicitud presentada, y la misma, será
            enviada al correo electrónico que proporcione en su solicitud; en
            caso de no recibir respuesta o no ser satisfactoria, usted puede
            acudir al Instituto Nacional de Acceso a la Información y Protección
            de Datos Personales (I.N.A.I), dentro de los quince días posteriores
            a la recepción o ausencia de la misma para solicitar la protección
            de sus datos, de conformidad con el artículo 45 de la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares.
          </p>

          <br />

          <p>
            <b>Cuarto.-</b>

            Bee Telecom conservará la información de sus datos personales
            durante el tiempo que dure la prestación de servicios, o durante más
            tiempo si fuera necesario, a efectos de la conservación de registros
            con fines legales.
          </p>

          <br />

          <p>
            <b>Quinto.-</b>

            Se entenderá que usted consiente tácitamente el tratamiento de sus
            datos, cuando habiéndose puesto a su disposición el presente aviso
            de privacidad, no manifieste su oposición.
          </p>

          <br />

          <p>
            <b>Sexto.-</b>

            Bee Telecom utilizará Cookies y web beacons para mejorar su
            experiencia y poder hacer más eficientes los servicios, asimismo,
            Bee Telecom podrá recabar su dirección IP, y usar cualquiera de
            estas herramientas tecnológicas con fines estadísticos; sin embargo,
            podrán ser deshabilitadas, mandando un correo electrónico a
            derechosarco@beetelecom.mx, solicitando su inhabilitación.
          </p>

          <br />

          <p>
            <b>Séptimo.-</b>

            Bee Telecom podrá efectuar en cualquier momento cambios y/o
            actualizaciones al presente aviso de privacidad, por lo que en ese
            entendido, dichos cambios y/o actualizaciones le serán notificados a
            través de nuestra página de internet www.beetelecom.mx, o puede
            solicitar una actualización del presente, al correo electrónico:
            derechosarco@beetelecom.mx.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Aviso",

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.legals {
  background: #fecd00;
}

.legals .legals-content {
  padding-top: 150px;
  padding-bottom: 150px;
}

.legals .legals-content h2 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 40px;
  text-shadow: 1px 1px black, -1px -1px #ffffff;
}

.legals .legals-content p {
  font-weight: 600;
  font-size: 15px;
}

.legals .legals-content p b {
  color: #ffffff;
  font-size: 17.5px;
}
</style>
